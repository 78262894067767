@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Kanit', sans-serif;
}

:root {
    --text-: #E5E7F4;
    --text-secondary: #202740;
    --blue-gradient: linear-gradient(90deg, #202740, #000000);
    --blue: #202740;
}

html {
    scroll-behavior: smooth;
}

body, html {
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(100vh);
    scroll-snap-type: y mandatory;
}
  
.scroll-point {
    scroll-snap-align: start; /* This will snap the start (top) of the container to the viewport */
    padding-top: 5rem;
}

.swiper-button-prev, .swiper-button-next {
    color: white !important;
    margin: 0rem 1rem !important;
}

.swiper-pagination-bullet {
    background-color: white !important;
}

.link-inactive {
	pointer-events: none;
	cursor: default;
	border-color: gray !important;
	color: gray !important;
}

svg {
	width: 100%; height: 100%;
}
svg text {
	animation: stroke 4s forwards;
	stroke-width: 2;
	stroke: white;
	font-size: 140px;
}
@keyframes stroke {
	0% {
		fill: rgba(72,138,20,0); 
		stroke: rgb(255, 255, 255);
		stroke-dashoffset: 25%; 
		stroke-dasharray: 0 50%; 
		stroke-width: 2;
	}
	70% {
		fill: rgba(72,138,20,0); 
		stroke: rgb(255, 255, 255); 
	}
	100% {
		fill: rgb(255, 255, 255); 
		stroke: rgba(255, 255, 255, 0); 
		stroke-dashoffset: -25%; 
		stroke-dasharray: 50% 0; 
		stroke-width: 1;
	}
}
