.slide--container {
    height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-image: url('../../public/images/other/sliderbg.png');
    background-position-x: -8vw;
    background-repeat: no-repeat;
    background-size: contain;
}

.slide--info {
    padding: 2rem 2rem;
    color: white;
    width: 100%;
    height: max-content;
    margin: 2rem 5rem;
    background-color: rgba(0, 0, 0, 0.8);
    /* box shadow large */
    box-shadow: 0 0px 500px 100px rgba(0, 0, 0, 1);
    border-radius: 5%;
}

.slide--img {
    width: 40vw;
    height: 100%;
    object-fit: cover;
    object-position: center;
    animation: pan 5s infinite alternate;
}

.slide--preview {
    position: relative;
}

.slide--preview::after {
    content: "";
    position: absolute;
    width: 50%;
    height: 100%;
    left: 0;
    z-index: 100;
    background: linear-gradient(-90deg,hsla(0,0%,9%,0),hsla(0,0%,9%,.13),hsla(0,0%,9%,.26),hsla(0,0%,9%,.58),#000000);
}

.slide--title {
    font-size: 3rem;
    font-weight: 500;
    margin-bottom: 1rem;
    border-bottom: 2px solid white;
}

.slide--description {
    font-size: .9rem;
    font-weight: 300;
    color: rgb(209, 209, 209);
    margin-bottom: 1rem;
}

.slide--cta {
    margin-top: 2rem;
}

.slide--cta a {
    text-decoration: none;
    color: white;
    padding: .75rem;
    border: 1.75px solid white;
    border-radius: 2rem;
    margin-right: 1rem;
    transition: all 0.3s ease-in-out;
}

.slide--cta a:hover {
    cursor: pointer;
    color: black;
    border-color: black;
    background-color: white;
}

@keyframes pan {
    0% {
      object-position: left center;
    }
    100% {
      object-position: right center;
    }
  }