.profile {
    background-size: cover;
    background-repeat: no-repeat;
    width: 50vw;
}

.normal {
    background-image: url('../../public/images/other/profile.png');
    transition: all 0.3s ease-in-out;
}

.smile {
    background-image: url('../../public/images/other/profile_smile.png');
    transition: all 0.3s ease-in-out;
}