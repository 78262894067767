#form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

#form input {
    height: 5vh;
    width: 40vw;
    margin: 1rem;
    padding: 10px;
}

#form textarea {
    height: 40vh;
    width: 40vw;
    margin: 1rem;
    padding: 10px;
}

#form input, #form textarea {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    color: white;
    border: 1px solid white;
}

#form button {
    height: 6vh;
    width: 10vw;
    border: 1.75px solid white;
    border-radius: 2rem;
    margin-right: 1rem;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0,0,0,.5);
    color: white;
    font-size: medium;
}

#form button:hover {
    background-color: white;
    color: black;
}

label {
    color: white;
    text-align: left;
}

.container--thanks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
.container--thanks h2 {
    font-size: 3rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
}

.container--thanks h3 {
    font-size: 1.3rem;
    color: white;
    text-align: center;
    margin-bottom: 1rem;
}

.container--thanks img {
    width: 20%;
    object-fit: contain;
    object-position: center;
    border-radius: 5%;
}

.container--thanks {
    display: none;
}