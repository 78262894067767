.container--main {
  height: 90vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  background-image: url('../public/images/bg/bg4.png');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.container--tech-stack {
  background-image: url('../public/images/bg/techstackbackground.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.container--recent-work {
  height: 100vh;
  background-color: black;
}

.container--merits {
  height: 100vh;
  background: url('../public/images/bg/meritsbackground.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

.container--contact {
  background-image: url('../public/images/bg/bgcontact.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.contact--title {
  font-size: 3rem;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
}

.tech-stack--title {
  font-size: 3rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
}

.merits--title {
  font-size: 3rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
}

.tech-stack--sub-header {
  font-size: 2rem;
  font-weight: 300;
  color: gray;
  text-align: center;
  margin-bottom: 1rem;
}

.tech-stack--icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}

.recent-work--header {
  font-size: 2rem;
  color: white;
  text-align: center;
  width: 100vw;
  height: max-content;
}

.swiper {
  height: 90%;
}

.tech-stack--header {
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: 0 0px 500px 10px rgba(0, 0, 0, 1);
}

#container--svg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  opacity: .7;
}

#tech-stack--svg1 {
  height: 20vw;
  width: max-content;
  margin-top: 7rem;
  align-self: flex-start;
  margin-left: 5vw;
}

#tech-stack--svg2 {
  height: 20vw;
  width: max-content;
  margin-bottom: 7rem;
  align-self: flex-end;
}

.diploma {
  width: 50%;
  object-fit: contain;
  object-position: center;
  border-radius: 5%;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.9);
}

.merits--container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 5rem;
}

.merits--micro {
  display: grid; /* enable grid layout */
  grid-template-columns: repeat(2, 1fr); /* create two equal columns */
  grid-gap: 1rem; /* spacing between the grid items */

  align-self: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0 2rem;
  padding: 2rem;
  border-radius: 5%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}

.merits--micro img {
  width: 50%;
  object-fit: contain;
  object-position: center;
  border-radius: 5%;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.9);
}

.merits--sub-header {
  font-size: 1rem;
  font-weight: 300;
  color: white;
  margin: 1rem 0;
}

.merit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container--contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {

  nav {
    padding: 5vw;
  }

  .nav--item {
    font-size: small;
    margin: 0 1vw;
  }

  .container--main {
    display: block;
    font-size: small;
  }

  .summary--container {
    margin: 0 2rem;
  }

  .summary--container svg {
    margin-left: 16vw;
  }

  .summary {
    width: 90vw;
    text-align: center;
  }

  .summary a {
    font-size: 2vw;
    padding: 10px;
  }

  .card {
    text-align: left;
    width: 30vw;
  }

  .merits--container {
    display: flex;
    margin: 0 0;
    flex-direction: column;
  }

  .merits--container > img {
    margin-top: 5vh;
    align-self: center;
    width: 70vw;
  }

  #container--svg {
    display: none;
  }

  .stack-icon img {
    width: 22vw;
  }

  .tech-stack--header {
    margin-top: 10vh;
  }

  .slide--title {
    font-size: 7vw;
  }

  .slide--info {
    margin: 5vw 0;
    box-shadow: none;
  }

  .slide--description {
    font-size: 2vw;
  }

  .slide--container {
    display: block;
  }
  .slide--container img {
    width: 100%;
  }
  .slide--preview::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0;
  }

  #form input, #form textarea {
    width: 80vw;
    margin: 1rem 0;
  }

  #form button {
    width: 30vw;
  }

}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.socials a {
  color: white;
  filter: drop-shadow(0 0 0.75rem black);
}

.socials a:hover {
  cursor: pointer;
}

.socials i {
  padding: 0 .5rem;
}